import * as React from "react"

import { graphql } from "gatsby"

import Layout from "../components/layout"
import Seo from "../components/seo"
import StyledBackgroundSection from "../components/BackGroundSection"
import HeadingCaroussel from "../components/HeadingCaroussel"

const IndexPage = ({ data }) => {
  const CarouData = data.Caroussel.nodes.map(e => ({
    title: "Beirut Kapput ?",
    image: e.childImageSharp.fluid,
    desc: "ou les multiples visages de l’horreur.",
    leg: ``,
  }))
  console.log(data)
  return (
    <Layout>
      <Seo title="Beirut Kapput?" />
      <section id="main">
        <HeadingCaroussel data={CarouData} />
      </section>
    </Layout>
  )
}

export default IndexPage

export const query = graphql`
  query {
    Caroussel: allFile {
      nodes {
        childImageSharp {
          fluid(quality: 90, maxWidth: 1920) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
    }
  }
`
