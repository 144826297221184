import React, { Component } from "react"
import { Carousel } from "react-responsive-carousel"
import Img from "gatsby-image"
import { Link } from "gatsby"
import "react-responsive-carousel/lib/styles/carousel.min.css"
import ReactDOM from "react-dom"

export default function HeadingCaroussel({ data }) {
  return (
    <Carousel
      showThumbs={false}
      autoPlay={true}
      showStatus={false}
      infiniteLoop={true}
      className={"carcont"}
    >
      {data.map((e, index) => (
        <div key={index} className="Carou">
          <Img fluid={e.image} className="imgCarou" id={`media-cover`} />
          <div className="wrapper">
            <div>
              <h1
                style={{
                  height: "fitContent",
                  color: "white",
                  textAlign: "center",
                }}
                className="h2carou"
              >
                {e.title}
              </h1>
              <p className="desc">{e.desc}</p>
            </div>
            <p className="leg">
              Au Mina Image Center jusqu'au 4 juin. <br></br> Beyrouth, Liban.
            </p>
          </div>
        </div>
      ))}
    </Carousel>
  )
}
